*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

::-ms-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  
  border-width: 0;
  
  border-style: solid;
  
  border-color: #e5e5e5;
  
}

::before,
::after {
  --tw-content: '';
}

html,
:host {
  line-height: 1.5;
  
  -webkit-text-size-adjust: 100%;
  
  -moz-tab-size: 4;
  
  -o-tab-size: 4;
     tab-size: 4;
  
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  
  font-variation-settings: normal;
  
  -webkit-tap-highlight-color: transparent;
  
}

body {
  margin: 0;
  
  line-height: inherit;
  
}

h3,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b {
  font-weight: bolder;
}

code {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  
  font-variation-settings: normal;
  
  font-size: 1em;
  
}

small {
  font-size: 80%;
}

button,
input,
select,
textarea {
  font-family: inherit;
  
  -webkit-font-feature-settings: inherit;
          font-feature-settings: inherit;
  
  font-variation-settings: inherit;
  
  font-size: 100%;
  
  font-weight: inherit;
  
  line-height: inherit;
  
  letter-spacing: inherit;
  
  color: inherit;
  
  margin: 0;
  
  padding: 0;
  
}

button,
select {
  text-transform: none;
}

button,
input:where([type='button']),
input:where([type='reset']),
input:where([type='submit']) {
  -webkit-appearance: button;
  
  background-color: transparent;
  
  background-image: none;
  
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  
  outline-offset: -2px;
  
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  
  font: inherit;
  
}

dd,
h3,
h6,
p {
  margin: 0;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  
  color: #a3a3a3;
  
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  
  color: #a3a3a3;
  
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  
  color: #a3a3a3;
  
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  
  color: #a3a3a3;
  
}

button,
[role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img,
svg,
iframe,
object {
  display: block;
  
  vertical-align: middle;
  
}

img {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.gn-pointer-events-none {
  pointer-events: none !important;
}

.gn-invisible {
  visibility: hidden !important;
}

.gn-fixed {
  position: fixed !important;
}

.gn-absolute {
  position: absolute !important;
}

.gn-relative {
  position: relative !important;
}

.gn-inset-0 {
  inset: 0px !important;
}

.gn-bottom-0 {
  bottom: 0px !important;
}

.gn-left-0 {
  left: 0px !important;
}

.gn-right-0 {
  right: 0px !important;
}

.gn-top-0 {
  top: 0px !important;
}

.gn-m-0 {
  margin: 0px !important;
}

.gn-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.gn-my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.gn--ml-4 {
  margin-left: -1rem !important;
}

.gn-mb-1 {
  margin-bottom: 0.25rem !important;
}

.gn-mb-2 {
  margin-bottom: 0.5rem !important;
}

.gn-mb-3 {
  margin-bottom: 0.75rem !important;
}

.gn-mb-4 {
  margin-bottom: 1rem !important;
}

.gn-ml-1 {
  margin-left: 0.25rem !important;
}

.gn-ml-2 {
  margin-left: 0.5rem !important;
}

.gn-ml-4 {
  margin-left: 1rem !important;
}

.gn-ml-8 {
  margin-left: 2rem !important;
}

.gn-mr-2 {
  margin-right: 0.5rem !important;
}

.gn-mr-4 {
  margin-right: 1rem !important;
}

.gn-mr-8 {
  margin-right: 2rem !important;
}

.gn-mt-1 {
  margin-top: 0.25rem !important;
}

.gn-mt-2 {
  margin-top: 0.5rem !important;
}

.gn-mt-3 {
  margin-top: 0.75rem !important;
}

.gn-mt-4 {
  margin-top: 1rem !important;
}

.gn-block {
  display: block !important;
}

.gn-inline-block {
  display: inline-block !important;
}

.gn-flex {
  display: -webkit-box !important;
  display: flex !important;
}

.gn-hidden {
  display: none !important;
}

.gn-h-12 {
  height: 3rem !important;
}

.gn-h-24 {
  height: 6rem !important;
}

.gn-h-32 {
  height: 8rem !important;
}

.gn-h-6 {
  height: 1.5rem !important;
}

.gn-h-8 {
  height: 2rem !important;
}

.gn-h-full {
  height: 100% !important;
}

.gn-w-1\/2 {
  width: 50% !important;
}

.gn-w-24 {
  width: 6rem !important;
}

.gn-w-3\/12 {
  width: 25% !important;
}

.gn-w-32 {
  width: 8rem !important;
}

.gn-w-6 {
  width: 1.5rem !important;
}

.gn-w-6\/12 {
  width: 50% !important;
}

.gn-w-8 {
  width: 2rem !important;
}

.gn-w-8\/12 {
  width: 66.666667% !important;
}

.gn-w-full {
  width: 100% !important;
}

.gn-flex-1 {
  -webkit-box-flex: 1 !important;
          flex: 1 1 0% !important;
}

.gn-cursor-pointer {
  cursor: pointer !important;
}

.gn-resize-none {
  resize: none !important;
}

.gn-flex-col {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
          flex-direction: column !important;
}

.gn-flex-wrap {
  flex-wrap: wrap !important;
}

.gn-items-start {
  -webkit-box-align: start !important;
          align-items: flex-start !important;
}

.gn-items-end {
  -webkit-box-align: end !important;
          align-items: flex-end !important;
}

.gn-items-center {
  -webkit-box-align: center !important;
          align-items: center !important;
}

.gn-justify-end {
  -webkit-box-pack: end !important;
          justify-content: flex-end !important;
}

.gn-justify-center {
  -webkit-box-pack: center !important;
          justify-content: center !important;
}

.gn-justify-between {
  -webkit-box-pack: justify !important;
          justify-content: space-between !important;
}

.gn-overflow-hidden {
  overflow: hidden !important;
}

.gn-overflow-scroll {
  overflow: scroll !important;
}

.gn-rounded {
  border-radius: 0.25rem !important;
}

.gn-rounded-full {
  border-radius: 9999px !important;
}

.gn-border {
  border-width: 1px !important;
}

.gn-border-0 {
  border-width: 0px !important;
}

.gn-border-2 {
  border-width: 2px !important;
}

.gn-border-4 {
  border-width: 4px !important;
}

.gn-border-b {
  border-bottom-width: 1px !important;
}

.gn-border-r-2 {
  border-right-width: 2px !important;
}

.gn-border-t-2 {
  border-top-width: 2px !important;
}

.gn-border-solid {
  border-style: solid !important;
}

.gn-border-black {
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1)) !important;
}

.gn-border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(212 212 212 / var(--tw-border-opacity, 1)) !important;
}

.gn-border-gray-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(163 163 163 / var(--tw-border-opacity, 1)) !important;
}

.gn-border-gray-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(115 115 115 / var(--tw-border-opacity, 1)) !important;
}

.gn-border-red-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(252 165 165 / var(--tw-border-opacity, 1)) !important;
}

.gn-bg-available {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(102 153 0 / var(--tw-bg-opacity, 1)) !important;
}

.gn-bg-blue-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity, 1)) !important;
}

.gn-bg-closed {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(204 0 0 / var(--tw-bg-opacity, 1)) !important;
}

.gn-bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity, 1)) !important;
}

.gn-bg-gray-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(115 115 115 / var(--tw-bg-opacity, 1)) !important;
}

.gn-bg-green-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity, 1)) !important;
}

.gn-bg-green-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity, 1)) !important;
}

.gn-bg-limited {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 170 0 / var(--tw-bg-opacity, 1)) !important;
}

.gn-bg-main {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(204 0 0 / var(--tw-bg-opacity, 1)) !important;
}

.gn-bg-red-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1)) !important;
}

.gn-bg-red-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity, 1)) !important;
}

.gn-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1)) !important;
}

.gn-bg-yellow-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity, 1)) !important;
}

.gn-p-1 {
  padding: 0.25rem !important;
}

.gn-p-2 {
  padding: 0.5rem !important;
}

.gn-p-3 {
  padding: 0.75rem !important;
}

.gn-p-4 {
  padding: 1rem !important;
}

.gn-p-5 {
  padding: 1.25rem !important;
}

.gn-px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.gn-px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.gn-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.gn-px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.gn-px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.gn-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.gn-py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.gn-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.gn-py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.gn-py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.gn-pl-1 {
  padding-left: 0.25rem !important;
}

.gn-pl-2 {
  padding-left: 0.5rem !important;
}

.gn-pl-4 {
  padding-left: 1rem !important;
}

.gn-pr-1 {
  padding-right: 0.25rem !important;
}

.gn-text-center {
  text-align: center !important;
}

.gn-text-right {
  text-align: right !important;
}

.gn-text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.gn-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.gn-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.gn-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.gn-text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.gn-text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.gn-font-bold {
  font-weight: 700 !important;
}

.gn-font-medium {
  font-weight: 500 !important;
}

.gn-font-normal {
  font-weight: 400 !important;
}

.gn-font-semibold {
  font-weight: 600 !important;
}

.gn-uppercase {
  text-transform: uppercase !important;
}

.gn-leading-none {
  line-height: 1 !important;
}

.gn-text-gray-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(212 212 212 / var(--tw-text-opacity, 1)) !important;
}

.gn-text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(163 163 163 / var(--tw-text-opacity, 1)) !important;
}

.gn-text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(115 115 115 / var(--tw-text-opacity, 1)) !important;
}

.gn-text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(64 64 64 / var(--tw-text-opacity, 1)) !important;
}

.gn-text-gray-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(38 38 38 / var(--tw-text-opacity, 1)) !important;
}

.gn-text-green-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(16 185 129 / var(--tw-text-opacity, 1)) !important;
}

.gn-text-red-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(252 165 165 / var(--tw-text-opacity, 1)) !important;
}

.gn-text-red-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1)) !important;
}

.gn-text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1)) !important;
}

.gn-text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.gn-underline {
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
}

.gn-line-through {
  -webkit-text-decoration-line: line-through !important;
          text-decoration-line: line-through !important;
}

.gn-opacity-50 {
  opacity: 0.5 !important;
}

.gn-opacity-75 {
  opacity: 0.75 !important;
}

geronigo-bookit {
  font-size: 16px !important;
}

geronigo-bookit input, geronigo-bookit textarea {
  font-weight: 400 !important;
}

.hover\:gn-bg-gray-400:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:gn-text-red-600:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1)) !important;
}

.hover\:gn-opacity-75:hover {
  opacity: 0.75 !important;
}

@media (min-width: 768px) {
  .md\:gn-mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .md\:gn-mb-0 {
    margin-bottom: 0px !important;
  }

  .md\:gn-mt-0 {
    margin-top: 0px !important;
  }

  .md\:gn-inline-block {
    display: inline-block !important;
  }

  .md\:gn-w-1\/2 {
    width: 50% !important;
  }

  .md\:gn-w-80 {
    width: 20rem !important;
  }

  .md\:gn-w-auto {
    width: auto !important;
  }

  .md\:gn-flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }

  .md\:gn-flex-wrap {
    flex-wrap: wrap !important;
  }

  .md\:gn-items-center {
    -webkit-box-align: center !important;
            align-items: center !important;
  }

  .md\:gn-pl-1 {
    padding-left: 0.25rem !important;
  }

  .md\:gn-pl-2 {
    padding-left: 0.5rem !important;
  }

  .md\:gn-pr-1 {
    padding-right: 0.25rem !important;
  }

  .md\:gn-pr-2 {
    padding-right: 0.5rem !important;
  }
}